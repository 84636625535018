import React, { useState, useEffect } from "react";
import SpanImage from "../comp/SpanImage";
import { useParams, Link } from "react-router-dom";
import Navigation, { Nav1, Nav2, Nav3, NavFb } from "../comp/Navigation";
import HomeElement from "../comp/HomeElement";
import SearchForm from "../comp/SearchForm";
import Agent, { AgentBody } from "../comp/Agent";
import Marquee from "../comp/Marquee";
import Modal from "../comp/Modal";
import VELKI_APP_NAME, {
  VELKI_API_URL,
  VELKI_API_KEY,
} from "../config/Constant";

export default function Search() {
  const [admindata, setAdmindata] = useState({ admindata: [] });
  const [loading, setLoading] = useState(true); 
  // Step 1: Add loading state
  let {type, agentId } = useParams();

  const fetchDetaild = () => {
    fetch(`${VELKI_API_URL}/api/?agent_id=${agentId}&type=${type}`)
      .then((res) => res.json())
      .then((items) => {
        setAdmindata(items);
        setLoading(false); 
        // Step 4: Stop loading once data is fetched
      })
      .catch(() => setLoading(false)); // Step 5: Stop loading even if there is an error
  };

  useEffect(() => {
    fetchDetaild();
  }, []);

  if (loading) {
    return (
      <>
      {/* Step 2: Add a loading animation here */}
        <main className="container-xl d-flex gap-3 flex-column">
          <section className="menubar-section d-flex flex-column gap-3">
            <Nav1 />
            <Nav2 />
            <Nav3 />
            <Marquee />
            <NavFb />
          </section>
          <h5 className="text-center text-dark text-uppercase my-3">
            {VELKI_APP_NAME} Search
          </h5>
          <section className="table-section my-3">
            <div
              className="bg-prime p-2 border-radius"
              style={{ overflowX: "auto" }}
            >
              <table className="table m-0 mb-3" data-bs-theme="light">
                <tbody>
                  <tr>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td className="header">
                      <div className="text-center my-5">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </main>
        <Modal />
      </>
    );
  }

  if (admindata.status === 200) {
    return (
      <>
        <main className="container-xl d-flex gap-3 flex-column">
          <section className="menubar-section d-flex flex-column gap-3">
            <Nav1 />
            <Nav2 />
            <Nav3 />
            <Marquee />
            <NavFb />
          </section>
          <h5 className="text-center text-dark text-uppercase my-3">
            {VELKI_APP_NAME} SEARCH DATA
          </h5>
          <section className="table-section my-3">
            <div
              className="bg-prime p-2 border-radius"
              style={{ overflowX: "auto" }}
            >
              <table className="table m-0 mb-3" data-bs-theme="light">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Whatsapp</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Complain</th>
                  </tr>
                </thead>
                <tbody>
                  {admindata.data.map((item) => (
                    <AgentBody
                      key={item.id}
                      keyid={item.id}
                      rowcls="odd"
                      type={(() => {
                        switch (item.agent_type) {
                          case "site_admin":
                            return "Site Admin";
                          case "sub_admin":
                            return "sub_admin";
                          case "subadmin":
                            return "sub_admin";
                          case "customer_service":
                            return "Customer Service";
                          case "super_admin":
                            return "super";
                          case "superadmin":
                            return "super";
                          case "master_admin":
                            return "master";
                          case "affiliate":
                            return "Affiliate";
                          default:
                            return "";
                        }
                      })()}
                      name={item.agent_name}
                      id={item.id}
                      fb={item.agent_fb}
                      app={item.agent_phone}
                      phone={item.agent_phone}
                      uplink={item.agent_upline}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </section>          
        </main>
        <Modal />
      </>
    );
  } else {
    return (
      <>
        <main className="container-xl d-flex gap-3 flex-column">
          <section className="menubar-section d-flex flex-column gap-3">
            <Nav1 />
            <Nav2 />
            <Nav3 />
            <Marquee />
            <NavFb />
          </section>
          <h5 className="text-center text-dark text-uppercase my-3">
            {VELKI_APP_NAME} Search
          </h5>
          <section className="table-section my-3">
            <div
              className="bg-prime p-2 border-radius"
              style={{ overflowX: "auto" }}
            >
              <table className="table m-0 mb-3" data-bs-theme="light">
                <tbody>
                  <tr>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td className="header">
                      <div className="code" style={{ width: "100%" }}>
                        <h1>No record found.. Please try again</h1>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </main>
        <Modal />
      </>
    );
  }
}
