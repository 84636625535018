import React, { Component } from "react";
import SpanImage from "../comp/SpanImage";
import { Link } from "react-router-dom";
import SearchForm from "../comp/SearchForm";
import Navigation, {Nav1,Nav2,Nav3,NavFb} from "../comp/Navigation";
import Agent, { AgentBody } from "../comp/Agent";
import Modal from "../comp/Modal";
import Marquee from "../comp/Marquee";
import VELKI_APP_NAME, {
  VELKI_API_URL,
  VELKI_API_KEY,
  VELKI_HOME_URL,
} from "../config/Constant";


let admindata = [
  {
    id: "0",
    agent_type: "Site Admin",
    agent_name: "Agent Name",
    agent_phone: "000000000000",
    agent_upline: "1",
    agent_altphone: null,
    agent_email: "",
    agent_uodateon: "2022-09-17 01:10:29",
    agent_creted: "2022-09-17 01:10:29",
  },
];


export default class Admin extends Component {
  constructor() {
    super();
    this.state = {
      agents: admindata,
      isloading: false,
    };
  }
  async componentDidMount() {
    console.log("cdm");
    let url = `${VELKI_API_URL}/api/?agent_type=site_admin&type=agent_list`;     //"http://localhost/agent/api/?agent_type=site_admin";
    let res = await fetch(url);
    let parsedData = await res.json();
    this.setState({
      agents: parsedData.data,
      isloading: false,
    });
    console.log("Parsed");
    console.log(parsedData.data);
  }

  render() {
    const { agents, isloading } = this.state;
    console.log("Render Agents:");
    console.log(agents);
    console.log(isloading);

    return (
      <>     
        <main className="container-xl d-flex gap-3 flex-column">
        <section className="menubar-section d-flex flex-column gap-3">
            <Nav1/>
             <Nav2/>
             <Nav3/>              
            <Marquee />
            <NavFb/>
            </section>
          <h5 className="text-center text-dark text-uppercase my-3">
            {VELKI_APP_NAME} ADMIN LIST
          </h5>
          <section className="table-section my-3">
            <div className="bg-prime p-2 border-radius" style={{overflowX: 'auto'}}>
              <table className="table m-0 mb-3" data-bs-theme="light">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Whatsapp</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Complain</th>
                  </tr>
                </thead>
                <tbody>
                {agents.map((item) => (
                <AgentBody
                  key={item.id}
                  keyid={item.id}
                  rowcls="odd"
                  type={item.agent_type}
                  ctype="agent_list"
                  name={item.agent_name}
                  id={item.id}
                  fb={item.agent_fb}
                  app={item.agent_phone}
                  phone={item.agent_phone}
                  uplink={item.agent_upline}
                />
              ))}                

                </tbody>
              </table>
            </div>
          </section>
          {/*----------------------  Start reportSubAdminModal -----------------------------*/}
          <div className="modal fade" id="reportSubAdminModal" tabIndex={-1} aria-labelledby="reportModalLabel" aria-hidden="true" data-bs-theme="dark">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content bg-prime">
                <div className="modal-header m-3 mt-0 p-3 d-flex justify-content-start align-items-center" style={{borderBottom: '1px solid #ddd', borderRadius: '0px'}}>
                  <div className="text-center d-flex justify-content-start align-items-start p-0 gap-2" id="reportModalLabel">
                    <span className="agent-icon bg-white d-flex justify-content-center align-items-center rounded-circle" style={{width: '30px', height: '30px'}}>
                      <i className="fa-solid fa-user text-success" />
                    </span>
                  </div>
                  <span className="close-modal bg-danger rounded-circle d-flex justify-content-center align-items-center" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fa-solid fa-xmark" />
                  </span>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row text-white">
                      <div className="col-12 col-sm-6 mb-1">
                        <span>Name</span>
                        <p className="bg-sec border-radius py-1 px-2" />
                      </div>
                      <div className="col-12 col-sm-6 mb-1">
                        <span>Type</span>
                        <p className="bg-sec border-radius py-1 px-2" />
                      </div>
                      <div className="col-12 col-sm-6 mb-3">
                        <span>Number</span>
                        <p className="bg-sec border-radius py-1 px-2" />
                      </div>
                      <div className="col-12 col-sm-6 mb-3">
                        <span>Id Number</span>
                        <p className="bg-sec border-radius py-1 px-2" />
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
                          <div className="d-flex flex-column justify-content-center align-items-center gap-2"><span className="text-uppercase" id="reportAdminName">Admin</span><a id="reportAdminLink" href className="btn btn-danger btn-sm border-radius">Report to Admin</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*----------------------  End reportSubAdminModal -----------------------------*/}
        </main>
        <Modal/>
      
      {/* <div id="wrapper">
        <span>
          <Link to="/">
          {           
            <SpanImage url="/images/velki-agentlist-top.jpg" width="100%" height="auto" />  
            }
           
          </Link>
        </span>
        <Navigation/>
        <SearchForm/>
        <div className="table-responsive"style={{ minHeight: "50px" }}>
          <table className="data" style={{ width: "95%" }}>
            <thead>
              <Agent
                type="Type"
                name="Name"
                id="Id No"
                app="App Link"
                phone="Phone Number"
                uplink="Complain"
              />
            </thead>
            <tbody>
              {agents.map((item) => (
                <AgentBody
                  key={item.id}
                  keyid={item.id}
                  rowcls="odd"
                  type="Admin"//{item.agent_type}
                  name={item.agent_name}
                  id={item.id}
                  fb={item.agent_fb}
                  app={item.agent_phone}
                  phone={item.agent_phone}
                  uplink={item.agent_upline}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
      </>
    );
  }
}

