import React, { Component } from "react";
import SpanImage from "../comp/SpanImage";
import { Link } from "react-router-dom";
import Navigation, { Nav1, Nav2, Nav3, NavFb } from "../comp/Navigation";
import Agent, { AgentBody } from "../comp/Agent";
import Marquee from "../comp/Marquee";
import Modal from "../comp/Modal";
import HomeElement from "../comp/HomeElement";
import VELKI_APP_NAME, {
  VELKI_API_URL,
  VELKI_API_KEY,
  VELKI_HOME_URL,
} from "../config/Constant";

const facebook_group = "https://www.facebook.com/groups/livevelki";
let datarules = [
  {
    title: "কিভাবে একাউন্ট খুলবেনঃ",
    head: "",
    desc: `vellki.live তে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে
    হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি
    যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময় লেনদেন
    করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে
    লেনদেন করবেন তার বুঝতে হলে আপনার নিম্বের তথ্য গুলো পড়া জরুরী।`,
  },
  {
    title: "এজেন্ট লিস্টঃ",
    head: "",
    desc: `একাউন্ট খুলতে নিম্বের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। 
    এজেন্ট লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ এর মাধ্যমে যোগাযোগ করতে হবে। 
    হোয়াটসাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। 
    হোয়াটসাপ এ যোগাযোগ করতে হলে এজেন্ট লিস্টে হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা 
    ফোন নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন। 
    হোয়াটসাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে 
    থেকে ইন্সটল করে নিন। <br/>
    <a href="https://vellkiagentlist.live/master">অনলাইন মাষ্টার এজেন্ট লিস্টঃ</a> `,
  },
  {
    title: "এজেন্ট কয় প্রকারঃ",
    head: "",
    desc: `<a href="https://vellkiagentlist.live/superadmin">অনলাইন সুপার এজেন্ট লিস্টঃ</a>
     সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে দিতে পারেন। <br/>
     কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন কে জানাতে হবে। 
     উপরে মেনু তে <a href="https://vellkiagentlist.live/admin">এডমিন লিষ্ট</a> দেয়া আছে।
     <a href="https://vellkiagentlist.live/master"> অনলাইন মাষ্টার এজেন্ট লিস্টঃ</a>
       অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। 
       কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি <a href="https://vellkiagentlist.live/superadmin">সুপার এজেন্ট </a>এর কাছে অভিযোগ 
       করতে হবে। <br/>
       <a href="https://facebook.com/">বিস্তারিত জানতে এই লিঙ্ক এ ক্লিক করুন। </a> <br/><br/>
       লোকাল <a href="https://vellkiagentlist.live/master">মাষ্টার এজেন্ট লিস্টঃ</a> 
       <br/>লোকাল মাষ্টার এজেন্ট রা,
        শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। <br/>
        কিন্তু তাদের সাথে লেনদেন প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। 
        তাদের নামে কোন অভিযোগ কারো কাছে করা যাবে না।  <br/>
        লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা পরিচিত দের সাথে লেনদেন করে । 
        যারা আগে বাজি ধরিয়ে দিত, তাদের কেই মুলত লোকাল এজেন্ট দেয়া হয়েছে। 
        লোকাল এজেন্ট রা অনলাইনে আসে না এবং তারা তাদের পরিচয় গোপন রাখতে চায়। 
        লোকাল এজেন্ট দের সাথে অনলাইনে কোন ভাবেই লেনদেন করবেন না, 
        আর করে থাকলে তার দায়ভার পুরোটাই আপনার।`,
  },
  {
    title: "OFFICIAL WEBSITE",
    head: "",
    desc: `<a href="https://vellki.live">vellki.live</a>`,
  },
];

let admindata = [
  {
    id: "0",
    agent_type: "Site Admin",
    agent_name: "Agent Name",
    agent_phone: "000000000000",
    agent_upline: "1",
    agent_altphone: null,
    agent_email: "",
    agent_uodateon: "2022-09-17 01:10:29",
    agent_creted: "2022-09-17 01:10:29",
  },
];
console.log(datarules);
//"http://localhost/agent/api/?agent_type=site_admin";

export default class Rules extends Component {
  constructor() {
    super();
    this.state = {
      agents: admindata,
      isloading: false,
      datarules: datarules,
    };
  }

  async componentDidMount() {
    console.log("cdm");
    console.log(this.state.datarules);
    let a = this.state.datarules;
    let url = `${VELKI_API_URL}/api/?agent_type=master_admin`;
    let res = await fetch(url);
    let parsedData = await res.json();
    if (parsedData.data) {
      this.setState({
        agents: parsedData.data,
        isloading: false,
        datarules: a,
      });
    }
    // console.log("Parsed");
    // console.log(parsedData.data);
  }

  render() {
    const { agents, isloading, datarules } = this.state;
    // console.log("Render Agents:");
    // console.log(typeof agents);
    // console.log(agents[0]);
    // console.log(isloading);

    return (
      <>
        <div>
          <main className="container-xl d-flex gap-3 flex-column">
            <section className="menubar-section d-flex flex-column gap-3">
              <Nav1 />
              <Nav2 />
              <Nav3 />
              <Marquee />
              <NavFb />
            </section>

            <section className="agent-type">
              <div className="d-flex gap-3">
                <div className="bg-prime p-3">
                  <h6 className="text-center text-white">
                    {VELKI_APP_NAME} এজেন্ট নিয়োগ পদ্ধতিঃ
                  </h6>
                  <div className="bg-sec p-1 border-radius d-flex flex-column flex-md-row gap-1 mt-2">
                    <div
                      className="border-radius p-2 text-center text-white d-flex justify-content-between flex-column gap-3"
                      style={{ backgroundColor: "#272A34" }}
                    >
                      <div className="grid-heading6">
                        <h6>অনলাইন সুপার এজেন্ট নিয়োগঃ</h6>
                      </div>
                      <div>
                        <p>
                          আপনি আমাদের যেকোনো সাব-এডমিন বা এডমিনের সাথে যোগাযোগ
                          করে সুপার এজেন্ট হতে পারবেন। সুপার এজেন্ট রেট 40 টাকা,
                          যা ক্রয় এবং বিক্রয় উভয় ক্ষেত্রেই প্রযোজ্য। আর আপনি ২
                          লক্ষ টাকা এবং ৩০০০ পয়েন্ট ক্রয়ের মধ্য দিয়ে jOsh365 এর
                          একজন সুপার এজেন্ট হতে পারবেন। আপনার পয়েন্ট যদি বেশী
                          হয়ে যায়, সেক্ষেত্রে আপনি ৫০০০ পয়েন্ট একাউন্টে রেখে
                          বাকি পয়েন্ট আপনার আপলাইনের কাছে বিক্রি করতে
                          পারবেন।&gt;
                        </p>
                      </div>
                      <div>
                        <a
                          href="sub-admin"
                          className="btn btn-sm btn-danger text-white"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                    <div
                      className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column"
                      style={{ backgroundColor: "#272A34" }}
                    >
                      <div className="grid-heading6">
                        <h6>অনলাইন মাষ্টার এজেন্ট নিয়োগঃ</h6>
                      </div>
                      <div>
                        <p>
                          আপনি চাইলে যেকোনো সুপার এজেন্টের সাথে যোগাযোগ করেই
                          jOsh365 এর একজন মাষ্টার এজেন্ট হতে পারেন। আমাদের
                          jOsh365 এর মাষ্টার এজেন্টের পয়েন্ট রেট 60 টাকা। আপনি
                          আপনার সুপারের থেকে ক্রয় এবং বিক্রয় উভয় ক্ষেত্রেই রেট
                          পাবেন 60, আর আপনি ১০০০ পয়েন্ট এবং ১ লক্ষ টাকা জামানত
                          প্রদানের মধ্য দিয়ে jOsh365 এ মাষ্টার এজেন্ট হতে
                          পারবেন। আপনার পয়েন্ট যদি বেশী হয়ে যায়, সেক্ষেত্রে ২০০০
                          পয়েন্ট একাউন্টে রেখে বাকি পয়েন্ট আপনি আপনার সুপারের
                          কাছে বিক্রি করতে পারবেন। আর এঁকটি কথা মনে রাখবেন, যদি
                          আপনি অন্য কাউকে বেট ধরিয়ে দিয়ে থাকেন সেক্ষেত্রে
                          আপলাইনে সেই আইডির বিষয়ে অবগত করতে হবে,আর যদি এই বিষয়ে
                          কোম্পানি নিজ থেকে জানতে পারে তাহলে প্রমানের ভিত্তিতে
                          নিজে খেলার অপরাধে এজেন্টশীফ সহ জামানত বাজেয়াপ্ত করা
                          হবে
                        </p>
                        <p>-</p>
                      </div>
                      <div>
                        <a
                          href="super"
                          className="btn btn-sm btn-danger text-white"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                    <div
                      className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column"
                      style={{ backgroundColor: "#272A34" }}
                    >
                      <div className="grid-heading6">
                        <h6>লোকাল মাষ্টার অথবা সুপার নিয়োগঃ</h6>
                      </div>
                      <div>
                        <p>
                          লোকাল মাষ্টার বা সুপারএজেন্ট রা, শুধু ইউজার একাউন্ট
                          একাউন্ট খুলে দিতে পারেন। কিন্তু তাদের সাথে লেনদেন
                          প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। তাদের
                          নামে কোন অভিযোগ কারো কাছে করা যাবে না।
                        </p>
                        <p>-</p>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="btn btn-sm btn-danger text-white"
                        >
                          Your Responcibility
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="agent-type">
                  <div className="d-flex gap-3">
                    <div className="bg-prime p-3">
                      <h6 className="text-center text-white">
                        {VELKI_APP_NAME} এফিলিয়েটর নিয়োগ পদ্ধতিঃ
                      </h6>
                      <div className="bg-sec p-1 border-radius d-flex flex-column flex-md-row gap-1 mt-2">
                        <div
                          className="border-radius p-2 text-center text-white d-flex justify-content-between flex-column gap-3"
                          style={{ backgroundColor: "#272A34" }}
                        >
                          <div className="grid-heading6">
                            <h6>সুপার এফিলিয়েটর নিয়োগঃ</h6>
                          </div>
                          <div>
                            <p>
                              সুপার এফিলিটর হলে আপনি মাষ্টার এফিলিয়েটর নিয়োগ
                              দিতে পারবেন, সেক্ষেত্রে প্রতি মাষ্টার এফিলেটর থেকে
                              ৫% কমিশন উপভোগ করতে পারবেন আজীবন। সুপার এফিলিয়েটর
                              হতে আপনাকে ১ লক্ষ টাকা (অফেরতযোগ্য)প্রদান করতে
                              হবে।সুপার এফেলিয়েটর হতে নিচের বাটনে ক্লিক করুন।
                            </p>
                          </div>
                          <div>
                            <a
                              href="admin"
                              className="btn btn-sm btn-danger text-white"
                            >
                              Contact
                            </a>
                          </div>
                        </div>
                        <div
                          className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column"
                          style={{ backgroundColor: "#272A34" }}
                        >
                          <div className="grid-heading6">
                            <h6>মাষ্টার এফিলিয়েটর নিয়োগঃ</h6>
                          </div>
                          <div>
                            <p>
                              {VELKI_APP_NAME}- এ ডুকে মেনু বারে ডুকে,Join
                              Affiliate অপশনে ক্লিক করে আমাদের সুপার এফিলিয়েটর
                              লিষ্টে ডুকে ,যেকোনো সুপার এফিলেটর থেকে ২০% কমিশনে
                              মাষ্টার এফিলিয়েটর হতে পারবেন একদম ফ্রি তে এবং
                              সপ্তাহে উপভোগ করুন ২০% ইউজার লস কমিশন। সুপার
                              এফিলিয়েটর লিষ্টে যেতে নিচের বাটনে ক্লিক করুন
                            </p>
                            <p>-</p>
                          </div>
                          <div>
                            <a
                              href="affiliate"
                              className="btn btn-sm btn-danger text-white"
                            >
                              Contact
                            </a>
                          </div>
                        </div>
                        <div
                          className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column"
                          style={{ backgroundColor: "#272A34" }}
                        >
                          <div className="grid-heading6">
                            <h6>প্রোমো কোড বসাবেন কিভাবে সাইটে</h6>
                          </div>
                          <div>
                            <p>
                              প্রোমো কোড বসানোর জন্য আপনাকে সরাসরি এডমিনদের সাথে
                              যোগাযোগ করতে হবে,অফেরতযোগ্য ৫০ হাজার টাকা প্রদানের
                              মাধ্যমে আপনার প্রোমো কোড টি আমাদের সাইটে যুক্ত
                              করতে পারবেন, যা দিয়ে অনলাইন থেকেপ্রবেশকারী
                              ইউজএকাউন্ট খোলার সময় ব্যবহার করবে আর যা থেকে আপনি
                              ২০%কমিশন উপভোগ করবেন।
                            </p>
                            <p>-</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-prime p-3">
                      <h6 className="text-center text-white">
                        {VELKI_APP_NAME} এর লেনদেনের নিয়মঃ
                      </h6>
                      <div className="bg-sec text-white p-2 border-radius mt-3">
                        <p>
                          বিকাশ,নগদ,রকেটে ২৪ ঘন্টা ডিপোজিট করতে পারবেন আর উইথড্র
                          সকাল ১০ঃ০০ টা থেকে রাত ১২ঃ০০ টা অবধি করা যাবে। উইথড্র
                          রিকোয়েষ্ট পাঠানোর সময় থেকে ১ ঘন্টা অবধি সময় লাগতে পারে
                          উইথড্র সম্পন্ন হতে।
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="instruction-grid mb-4">
                  <div className="d-flex gap-3">
                    <div className="bg-prime p-3">
                      <h6 className="text-center text-white">
                       {VELKI_APP_NAME} এর লেনদেনের নিয়মঃ
                      </h6>
                      <div className="bg-sec text-center text-white p-2 border-radius">
                        <p>
                          লেনদেন বিকাশ,নগদ, রকেট এবং ব্যাংকের মাধ্যমে প্রতিদিন
                          সকাল ১০ঃ০০ টা থেকে রাত ১১ঃ০০ টা অবধি করতে পারবেন
                          লেনদেনের সময় ৩০ মিনিট থেকে ১ ঘন্টা অবধি হতে পারে কোন
                          বিশেষ কারণ বষত।
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="instruction-grid mb-4">
                  <div className="d-flex gap-3">
                    <div className="bg-prime p-3">
                      <h6 className="text-center text-white">
                        {VELKI_APP_NAME} এর জরিমানা প্রথাঃ
                      </h6>
                      <div className="bg-sec text-center text-white p-2 border-radius">
                        <p>
                          {VELKI_APP_NAME}- এর কোনো সাব-এডমিন,সুপার বা মাষ্টার
                          এজেন্ট যদি কোম্পানির দেয়া পয়েন্ট প্রাইজের বাইরে কম
                          রেটে ক্রয়-বিক্রয় করেন,এবং তা যদি কেউ প্রমান করতে
                          পারে,তাকে কোম্পানির পক্ষ হতে ১লক্ষ টাকা পুরস্কার দেয়া
                          হবে।এবং তার সাথে সাথে ওই এজেন্টের এজেন্টশীফসহ জামানত
                          বাজেয়াপ্ত করা হবে। তবে প্রমানকারী তখনি এই পুরস্কারের
                          যোগ্য বলে বিবেচিত হবে, যখন সে ওই রেটে এজেন্ট নিতে
                          সক্ষম হবে, এবং আমাদের কোন এজেন্ট তাকে সরাসরি প্রথম
                          জিজ্ঞেসে এই অফার দেয়,যদি এজেন্ট কে প্রলোভন বা বোকা
                          বানানোর ঘটনা ঘটে সেক্ষেত্রে কোন পুরস্কার দেয়া হবে না।
                        </p>
                      </div>
                    </div>
                    <div className="bg-prime p-3">
                      <h6 className="text-center text-white">
                        {VELKI_APP_NAME} এর সব শর্তঃ
                      </h6>
                      <div className="bg-sec text-center text-white p-2 border-radius mt-3">
                        <p>
                          সকল সুপার এবং মাস্টার এজেন্ট এবং সকল ইউজার এই নিয়মের
                          অন্তর্গত।
                        </p>
                        <p>
                          ওয়েবসাইট টি যেহেতু বেটফেয়ার এর মাধ্যমে চলে – সেহেতু
                          বেটফেয়ার এর নিয়ম এবং স্বিদ্ধান্ত অনুযায়ী সব কিছু
                          হয়। এই সাইটে বেট করতে হলে আপনাকে বেটফেয়ার এবং
                          jOsh365  ম্যানেজমেন্ট যে কোন স্বিদ্ধান্ত আপনাকে বিনা
                          শর্তে মেনে নিতে হবে।
                        </p>
                        <p>
                          প্রতিবার এজেন্ট এর কাছ থেকে পয়েন্ট নেবার আগে – এজেন্ট
                          এর কাছে লেনদেন এর তথ্য জেনে নিতে হবে। যেহেতু এজেন্ট এক
                          এক সময় এক ভাবে লেনদেন করে সেহেতু এই তথ্য জানা জরুরী।
                        </p>
                        <p>
                          সকাল ১০ঃ০০ এর আগে এবং রাত ১১ঃ০০ এর পরে কোন ইউজার যদি
                          এজেন্ট কে টাকা পাঠায় – অই টাকার দায়ভার jOsh365 
                          নিবে না।
                        </p>
                        <p>
                          বিকাশ/নগদ এ টাকা পাঠানোর ২ ঘন্টার মধ্যে এজেন্ট এর সাথে
                          যোগাযোগ করে পয়েন্ট নিতে হবে। ২ ঘন্টা পরে – টাকা
                          কাউন্ট করা হবে না।
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>

          
          </main>
    <Modal/>
        </div>

        {/* <div id="wrapper">
          <span>
            <Link to="/">
              {
                
                <SpanImage
                  url="/images/velki-agentlist-top.jpg"
                  width="100%"
                  height="auto"
                />
              }             
            </Link>
          </span>
          <Navigation />
          <div className="dashboard-contents">
            <div id="licenseinfo">
              <div className="header">
                <div className="title">কুইক মাস্টার এজেন্ট নম্বর:</div>
                <div className="legend"></div>
              </div>
              <div className="body">
                <div className="code">
                  <div
                    className="table-responsive"
                    style={{ minHeight: "50px", width: "95%" }}
                  >
                    <table className="data" style={{ width: "95%" }}>
                      <thead>
                        <Agent
                          type="Type"
                          name="Name"
                          id="Id No"
                          app="App Link"
                          phone="Phone Number"
                          uplink="Complain"
                        />
                      </thead>
                      <tbody>
                        {agents.slice(0, 1).map((item) => (
                          <AgentBody
                            key={item.id}
                            keyid={item.id}
                            rowcls="odd"
                            type={(() => {
                              switch (item.agent_type) {
                                case "site_admin":
                                  return "Site Admin";
                                case "customer_service":
                                  return "Customer Service";
                                case "super_admin":
                                  return "Super";
                                case "super_sub":
                                  return "Super Sub";
                                case "master_admin":
                                  return "Master";
                                default:
                                  return "Customer";
                              }
                            })()}
                            name={item.agent_name}
                            id={item.id}
                            fb={item.agent_fb}
                            app={item.agent_phone}
                            phone={item.agent_phone}
                            uplink={item.agent_upline}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {datarules.map((item, index) => (
            <HomeElement
              key={index}
              title={item.title}
              head={item.head}
              desc={item.desc}
            />
          ))}
        </div> */}
      </>
    );
  }
}
