import React, { Component } from 'react'


class SpanImage extends Component {

  render() {
    return (
        <img src={this.props.url} alt="DisplayImage" width={this.props.width} height={this.props.height}/>
    );
  }
}
export default  SpanImage;