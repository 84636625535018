import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import List from './List'

export default function Navigation() {
  return (
    <div id="navigation">
         <ul className="menubar">           
            <List href="/" url="/images/rooms.png" width="" height="" text=""/>
            <List href="/services" url="" width="" height="" text="কাষ্টমার সার্ভিস"/>
            <List href="/admin" url="" width="" height="" text="এডমিন"/>
            <List href="/subadmin" url="" width="" height="" text="সাব এডমিন"/>
            {/* <List href="/supersub" url="" width="" height="" text="সুপার সাব"/>
            <List href="/superadmin" url="" width="" height="" text="সুপার"/> */}
            <List href="/master" url="" width="" height="" text="মাস্টার"/>  
            <List href="/affiliate" url="" width="" height="" text="Affiliate"/>        
       </ul>
    </div>
  )
}
class Nav1 extends Component {
    render() {
        return (
          <div className="d-flex justify-content-around align-items-center py-2">
          <div
            className="d-flex d-md-none"
            data-bs-toggle="modal"
            data-bs-target="#searchAgentModal"
          >
            <i className="fa-solid fa-magnifying-glass text-white fs-3" />
          </div>
          <div>
            <img
              src="/assets/images/josh365.webp"
              alt=""
              className="img-fluid"
              style={{ height: "60px" }}
            />
          </div>
          <div
            className="d-flex d-md-none"
            data-bs-toggle="modal"
            data-bs-target="#mobileMenuModal"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="grid"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="svg-inline--fa fa-grid fa-lg fs-3"
            >
              <path
                fill="currentColor"
                d="M0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40L0 72zM0 232c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48zM128 392l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40zM160 72c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48zM288 232l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40zM160 392c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48zM448 72l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40zM320 232c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48zM448 392l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40z"
                className="text-white"
              />
            </svg>
          </div>
        </div>
        )
    }
}
class Nav2 extends Component {
    render() {
        return (
          <div className="mainmenu d-flex justify-content-between align-items-center">
          <div className="d-none d-md-flex">
            <button
              className="d-flex justify-content-center align-items-center btn bg-sec text-white px-4"
              data-bs-toggle="modal"
              data-bs-target="#searchAgentModal"
            >
              <i className="fa-solid fa-magnifying-glass text-warning" />
              Search Agent
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100">
            <nav>
              <ul className="d-flex justify-content-center align-items-center text-white gap-2 gap-md-4 flex-wrap">
                <li>
                  <Link to="/" className="">
                    <i className="fa-solid fa-house" />
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/admin" className="">
                    <i className="fa-solid fa-user" />
                    Admin
                  </Link>
                </li>
                <li>
                  <Link to="/subadmin" className="">
                    <i className="fa-solid fa-user" />
                    Sub Admin
                  </Link>
                </li>
                <li>
                  <Link to="/superadmin" className="">
                    <i className="fa-solid fa-user" />
                    Super
                  </Link>
                </li>
                <li>
                  <Link to="/master" className="">
                    <i className="fa-solid fa-user" />
                    Master
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="">
                    <i className="fa-solid fa-user" />
                    Support
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        )
    }
}

class Nav3 extends Component{
  render(){
    return(
      <div className="mainmenu d-flex justify-content-between align-items-center">
                <div className="d-none d-md-flex">
                  <button
                    className="d-flex justify-content-center align-items-center btn bg-sec text-white px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#searchAgentModal"
                  >
                    <i className="fa-solid fa-magnifying-glass text-warning" />
                    Search Agent
                  </button>
                </div>
                <div className="d-flex justify-content-center align-items-center w-100">
                  <nav>
                    <ul className="d-flex justify-content-center align-items-center text-white gap-2 gap-md-4 flex-wrap">
                      <li>
                        <Link to="/affiliate" className="">
                          <i className="fa-solid fa-user" /> Join Affiliate
                        </Link>
                      </li>
                      <li>                        
                        <Link to="/rules"><i className="fa-solid fa-user" />Rules</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
    )
  }
}
class NavFb extends Component{
  render(){
    return(
      <nav className="nav-social d-flex justify-content-around align-items-center gap-3 flex-column flex-md-row text-white py-2 py-md-1">
      <div>
        <p className="mb-0">আমাদের অফিসিয়াল ফেসবুক গ্রুপঃ</p>
      </div>
      <div>
        <a
          href="https://www.facebook.com/share/g/CisKU6Pa9jzhJGiC/" target="_blank" rel="noreferrer"
          className="btn btn-primary d-flex gap-2 justify-content-center align-items-center px-5"
        >
          <i className="fa-brands fa-facebook-f" />
          এখানে ক্লিক করুন
        </a>
      </div>
    </nav>
    )
  }
}
export {Nav1,Nav2,Nav3,NavFb};