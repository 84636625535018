import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ComplainLink extends Component {
  render() {
    let newType;

    switch (this.props.ctype) {
      case "site_admin":
      case "admin":
        newType = "agent_list";
        break;
      case "sub_admin":
      case "subadmin":
        newType = "sub_admin";
        break;
      case "customer_service":
        newType = "services";
        break;
      case "super_admin":
      case "superadmin":
        newType = "super";
        break;
      case "master_admin":
        newType = "master";
        break;
      case "affiliate":
        newType = "Affiliate";
        break;
      default:
        newType = "agent_list";
        break;
    }

    return (
      <>
        <Link to={`/complain/${newType.toLowerCase()}/${this.props.url}`} target="_blank" rel="noreferrer">
          অভিযোগ
        </Link>
      </>
    );
  }
}
// import React, { Component } from 'react';
// import {Link} from 'react-router-dom';

// export default class ComplainLink extends Component {
//   render() {
//     return (
   
//       <>
//         {/* <a href={`complain/${this.props.url}`} target="_blank">Complain</a> */}
//         <Link  to={`/complain/${this.props.ctype.toLowerCase()}/${this.props.url}`} target="_blank" rel="noreferrer">
//         অভিযোগ
//         </Link>
//         </>
       

//     )
//   }
// }
