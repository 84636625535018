import React, { useState } from "react";

const SearchForm = () => {
  const [agentId, setAgentId] = useState("");
  const [type, setType] = useState("");

  return (
    <form action={`/search/${type}/${agentId}`} method="GET">
      <label htmlFor="agentId">Agent ID:</label>
      <input
        className="form-control bg-sec border rounded"
        type="text"
        id="agentId"
        name="agentId"
        value={agentId}
        onChange={(e) => setAgentId(e.target.value)}
        required
      />
      <label htmlFor="type">Type:</label>
      <select
        className="form-control bg-sec border rounded"
        type="text"
        id="type"
        name="type"
        value={type}
        onChange={(e) => setType(e.target.value)}
        required
      >
        <option value="">Select Type</option>
        <option value="agent_list">Admin</option>
        <option value="sub_admin">Subadmin</option>
        <option value="super">Super</option>
        <option value="master">Master</option>
        <option value="services">Suport</option>
        <option value="affiliate">Affiliate</option>
      </select>
      <button className="btn bg-sec float-end" type="submit">
        Search
      </button>
    </form>
  );
};

export default SearchForm;
