import React, { Component } from "react";
import Whatsapp from './Whatsapp';
import Facebook from './Facebook';
import PhoneCall from './PhoneCall';
import ComplainLink from './ComplainLink';

class Agent extends Component {
  render() {
    return (
      <>
        <tr id={this.props.keyid} className={this.props.rowcls}>
          <th align="center">{this.props.type}</th>
          <th align="center">{this.props.name}</th>
          <th align="center">{this.props.id}</th>
          <th align="center">{this.props.app}</th>
          <th align="center">{this.props.phone}</th>
          <th align="center">{this.props.uplink}</th>
        </tr>
      </>
    );
  }
}

class AgentBody extends Component {
  render() {
    return (
      <>      
        <tr id={this.props.keyid}>
          <td>
            <div
              className="d-flex mx-auto flex-column justify-content-center py-1 px-2 fw-medium align-items-center bg-white border-radius text-black"
              style={{ maxWidth: "max-content" }}
            >
              <span>ID</span>
              <span>{this.props.id}</span>
            </div>
          </td>
          <td>
            <a href={`https://wa.me/${this.props.phone}`}>
              <i className="fa-brands fa-whatsapp text-white fs-1" />
            </a>
          </td>
          <td>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <span className="d-none d-md-block">{this.props.phone}</span>
              <small className="d-block d-md-none" style={{ fontSize: "15px" }}>
                {this.props.phone}
              </small>
              <small className="badge badge-sm text-bg-success mt-2">Whatsapp</small>
            </div>
          </td>
          <td>
            <button
              style={{ backgroundColor: "red", color: "#fff", fontSize: "16px" }}
              className="btn btn-sm py-0 reportSubAdmin"
              data-id={`https://wa.me/${this.props.uplink}`}
              data-admin={this.props.id}
            >
              <ComplainLink url={this.props.uplink} ctype={this.props.ctype}/>
            </button>
          </td>
        </tr>
      </>
    );
  }
}

export default Agent;
export  {AgentBody};

{/* <tr id={this.props.keyid} className={this.props.rowcls}>
<td align="center">{this.props.type}</td>
<td align="center">{this.props.name}</td>
<td align="center">{this.props.id}</td>
<td align="center">  
  {(this.props.phone)?<Whatsapp phone={this.props.phone} width="25"/>:''}
  {(this.props.fb)?<Facebook fb={this.props.fb} width="25"/>:''}
</td>
<td align="center"><PhoneCall phone={this.props.phone}/></td>
<td align="center"><ComplainLink url={this.props.uplink}/></td>
</tr> */}